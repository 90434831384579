.login {
	width: 100%;
	height: 100%;

	display: flex;
	flex-direction: column;

	background: radial-gradient(#1f9eff, #000000);
}

.login .siteVersion {
	padding: 5px;
	position: absolute;
	right: 0px;
	bottom: 0px;
	color: rgb(187, 187, 187);
	font-size: 0.625rem;
}

.login .formBox {
	flex: 0 1 none;
	margin-top: auto;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: auto;

	padding: 40px;
	border-radius: 5px;

	background-color: #FFFFFF;
	box-shadow: 5px 7px 10px rgba(0, 0, 0, 0.1);
}

.login .formBox>*:not(:first-child) {
	margin-top: 20px;
}

.login .new-user-lang .langDropdown {
	max-width: 100%;
	width: 100%;
}

.login .formBox .textField,
.login .formBox .passwordField {
	text-align: left;
}

.spinner-border {
	margin-right: 12px;
}

.login .formBox .return-button {
	margin-top: 0;
	float: left;
}

.login .formBox .reset-password {
	position: relative;
	bottom: -30px;
	margin-top: 0px;
}

@media only screen and (max-width: 767px) {
	.login .formBox {
		padding: 15px;
	}

	.login .formBox .reset-password {
		bottom: 0;
	}
}

.login #typeDropdown {
	padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
	width: auto;
}

.login #typeDropdown::after {
	position: inherit;
}