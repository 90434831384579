.reportSaveModal .modal-body .time-selection {
	margin-top: 20px;
}

.reportSaveModal .modal-body .time-selection>.buttons>*:not(:first-child) {
	margin-left: 20px;
}

.reportSaveModal .modal-body .time-selection>.time-interval {
	margin-top: 10px;
}

.reportSaveModal .modal-body .time-selection>.time-interval>* {
	display: inline-block;
	padding-right: 1rem;
}

.dateTimepicker .timeStart {
	padding-right: 10px;
}