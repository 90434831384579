.customers .leftSidePanel .card-body {
	text-align: center;
	padding-bottom: 10px;
}

.customers .leftSidePanel .card-body .btn.addCustomers-button {
	margin-top: 10px;
}

.customers .customers-table .checkbox {
	margin-top: 0px;
	margin-bottom: 0px;
}

.customers .customers-table-wrapper {
	max-height: 100%;
	overflow-y: auto;
}

.customers .customers-table-wrapper table.customers-table {
	cursor: pointer;
}