/* Token view*/
.modal-dialog .modal-footer .open-passResetView.btn,
.modal-dialog .modal-footer .passResetButton {
	float: left;
}

.modal-dialog .passResetView {
	text-align: center;
}

.modal-dialog .passResetView>.btn:nth-of-type(1) {
	margin-right: 200px;
}

.modal-dialog .passResetView>.title {
	font-size: 1.25rem;
	font-weight: bold;
	margin-bottom: 20px;
}