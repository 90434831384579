.userSettings .leftSidePanel .card-body {
	text-align: center;
}

.userSettings .changePassword {
	max-width: 500px;
	text-align: center;
	margin: auto;
	padding: 2rem 0;
}

.userSettings .changePassword .form-group {
	text-align: left;
}

.userSettings .infoMessages {
	width: 100%;
	text-align: center;
	margin: auto;
}

.userSettings .infoMessages .messages-wrapper {
	text-align: left;
}

.userSettings button[type=submit] {
	margin-top: 10px;
}

@media only screen and (min-width: 768px) {
	.userSettings .infoMessages .messages-wrapper>* {
		margin: 20px;
		width: calc(100% - 40px);
	}
}

.userSettings .leftSidePanel .menuPanelStaticLoose .card-body .dropdown #lanaguageSelect::after {
	position: inherit;
}