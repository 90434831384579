.project-data-statistics {
	margin-top: 10px;
	font-size: 0.875rem;
}

.project-data-statistics>.title {
	text-align: center;
	font-weight: bold;
	font-size: 1.125rem;
	padding: 0.75rem 0 0.75rem 0;
}

.project-data-statistics>.subtitle {
	text-align: center;
	font-weight: bold;
	font-size: 1rem;
}

.project-data-statistics .table thead tr th {
	text-align: center;
	padding: 0.313rem;
}

.project-data-statistics .table thead tr th.time-header {
	text-align: left;
}

.project-data-statistics .table thead tr th.min-header,
.project-data-statistics .table thead tr th.max-header {
	text-align: right;
}

.project-data-statistics .table tbody tr td {
	text-align: center;
	padding: 0.313rem;
}

.project-data-statistics .table tbody tr td.logger-name {
	text-align: left;
}

.project-data-statistics .table tbody tr td.max,
.project-data-statistics .table tbody tr td.min {
	text-align: right;
}

.project-data-statistics .table tbody tr td.time {
	text-align: left;
}

.project-data-statistics .mso-indent {
	mso-char-indent: 2;
}

@media only screen and (max-width: 767px) {
	.project-data-statistics.cardBackground {
		overflow: scroll;
	}
}

.statistics-dl-icon {
	stroke-width: 0.05rem;
	margin-right: 5px;
}

.statistics-dl {
	position: absolute;
	right: 0.75rem;
	margin-top: 0.75rem;
}