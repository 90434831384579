.modal .limitEditor {
	width: 100%;
	padding: 1rem;
	margin-left: 1rem;
}

.limit-editor-modal #typeDropdown {
	max-width: 15vw;
	min-width: fit-content;
}

.limitEditor .limit {
	max-width: 200px;
}

.limitEditor .addButtonWrapper {
	padding: 5px;
}

.limitEditor .tableButtonColumn {
	vertical-align: middle;
	text-align: center;
}

.limitEditor .tableButtonColumn button {
	vertical-align: text-bottom;
}

.limitEditor .tableWrapper {
	display: inline-block;
	max-height: calc(100% - 50px);
	overflow-y: auto;
}

.limitEntries thead tr th,
.limitEntries tbody tr td {
	padding: 5px;
}

.limitEntries {
	margin: 0;
}

.limitEntries .limitTableEntry td:first-child {
	vertical-align: middle;
}

.reactIcon {
	stroke-width: 0.15rem;
	vertical-align: bottom;
	width: 1rem;
	height: auto;
}

.tableButtonColumn .remove-limit-button {
	display: flex;
	align-items: center;
	padding: 8px;
	margin: auto;
}

.limit-edit {
	margin-bottom: 5px;
}

@media (max-width: 991.98px) {
	.limit-editor-modal #typeDropdown {
		max-width: 100%;
	}

	.modal .limitEditor {
		margin-left: 0;
	}
}