.spinner:before {
	content: "";
	box-sizing: border-box;
	position: absolute;
	top: 50%;
	left: 50%;
	height: 60px;
	width: 60px;
	margin-top: -30px;
	margin-left: -30px;
	border-radius: 50%;
	border: 3px solid lightgray;
	border-top-color: rgb(86, 156, 255);
	animation: spinner 0.7s linear infinite;
	z-index: 20;
}

.spinner > table{
	opacity: 0%;
}

@keyframes spinner {
	to {
		transform: rotate(360deg);
	}
}

.spinner-border.systems-spinner {
	top: 50%;
	left: 50%;
	position: relative;
	height: 5rem;
	width: 5rem;
	margin: 0;
}