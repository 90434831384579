.datasetSelect .title {
	text-align: left;
	margin-bottom: 10px;
}

.datasetSelect .selectionOptions {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-column-gap: 10px;
	grid-row-gap: 10px;
	margin-top: 10px;
}

.datasetSelect .selectionOptions .typeOption,
.datasetSelect .selectAll {
	padding: 0px;
	margin: 0px;
}

.datasetSelect .selectAll {
	padding-left: 10px;
	padding-right: 10px;
	width: 100%;
}

.datasetSelect .selectionOptions .typeOption.none-active,
.datasetSelect .selectAll.none-active {
	background-color: lightgray;
	color: #18151E;
	border-color: darkgray;
	font-weight: bold;
}

.datasetSelect .selectionOptions .typeOption.none-active:hover,
.datasetSelect .selectAll.none-active:hover {
	border-radius: 3px;
	text-align: center;
	color: #18151E;
	box-shadow: inset 0 0 100px 100px rgba(112, 112, 112, 0.3);
}

.some-active,
.datasetSelect .some-active {
	background-color: #B8E2FF;
	color: #18151E;
	font-weight: bold;
}