@media only screen and (min-width: 1024px) and (orientation: portrait) {
	.navigationBar .brandIMG {
		max-height: 100%;
		max-width: 100%;
		width: auto;
		height: auto;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
		background-color: #1e435c;
	}
}

.navbar-nav .nav-item a.active {
	background-color: #E2DDD5;
	border-bottom-color: #E2DDD5;
}

.navigationBar .navbar-brand {
	background-color: #1e435c;
	margin-right: 10px;
}

.navigationBar .brandTitle {
	padding-top: 15px;
	padding-left: 10px;
	padding-right: 10px;
	color: #FFFFFF;
	text-decoration: underline;
}

/* Bootstrap overrides */
.navbar .container {
	width: auto;
}

.navbar-collapse {
	margin-right: 0;
	margin-left: 0;
	background-color: white;
}

.container,
.navigationBar.navbar {
	padding: 0;
}

.navbar .navbar-brand,
.navbar .navbar-brand a {
	padding: 0px;
}

.navbar .navbar-brand {
	background-color: #1e435c;
	min-height: 50px;
}

@media only screen and (max-width: 1023px) and (orientation: portrait) {
	.router .navbar {
		background-color: #1e435c;
	}

	.navbar .navbar-brand {
		margin: auto;
		padding-left: calc(1.5em + 0.75rem + 2px);
	}

	.navigationBar .navbar-toggler {
		background-color: whitesmoke;
	}

	.brandIMG {
		padding-top: 6px;
	}

	.navbar-nav .nav-item a.active {
		background-color: #e7e7e7;
		border-bottom-color: #e7e7e7;
	}
}

@media only screen and (min-width: 1200px) {
	.navbar .navbar-brand {
		min-width: 385px;
	}
}

@media only screen and (min-width: 768px) and (max-height: 1024px) and (orientation: landscape) {
	.navbar .navbar-brand {
		min-width: 325px;
	}
}

.navbar-nav {
	margin: 0;
}

.navbar-toggle {
	background-color: #EEEEEE;
	position: fixed;
	right: 0;
	top: 0;
}

.navbar-brand img {
	opacity: 1;
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
}

.navbar-brand:hover img {
	opacity: .5;
}

.navbar a {
	min-height: 50px;
	white-space: nowrap;
}

.miranLogo li {
	width: 100%;
	margin-top: 5px;
}

.collapsing {
	-webkit-transition: height .1s ease;
	-o-transition: height .1s ease;
	transition: height .1s ease;
}

.rightMenu {
	position: absolute !important;
	right: 0;
}

.navbar {
	background-color: white;
}

.outline-miranlink.btn-outline-primary {
	color: #1e435c;
	border-color: #1e435c;
}

.outline-miranlink.btn-outline-primary:active,
.outline-miranlink.btn-outline-primary:hover {
	color: #fff;
	background-color: #1e435c;
	border-color: #1e435c;
}