.timeselector .unit {
	padding-left: 5px;
}

.timeselector form {
	display: inline-block;
}

.timeselector input {
	padding: 0 0 0 0.75rem;
}

.timeselector .timeselector-input-unit:not(:last-child) {
	padding-right: 15px;
}

.timeselector .timeselector-input-unit input.timeselector-number-input {
	width: 55px;
	height: 30px;
}

.timeselector .timeselector-input-unit input.timeselector-number-input {
	width: 55px;
}

.timeselector .timeselector-input-unit input[type=number]::-webkit-inner-spin-button,
.timeselector .timeselector-input-unit input[type=number]::-webkit-outer-spin-button {
	opacity: 1;
	width: 5px;
	height: 28px;
}

.timeselector .timeselector-input-unit input[type=number].mobile-input::-webkit-inner-spin-button,
.timeselector .timeselector-input-unit input[type=number].mobile-input::-webkit-outer-spin-button {
	width: 20px;
	height: 28px;
}