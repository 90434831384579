.plotColorsEditor .plotColorColumn {
	text-align: center;
}

.plotColorsEditor .tableWrapper>* {
	margin: 0px 20px 0px 0px;
}

.plotColorsEditor .tableWrapper {
	display: inline-block;
	max-height: 100%;
	width: 100%;
	overflow-y: auto;
}

.plotColorsEditor .loggerEntry .title {
	margin: 0px 0px 5px 5px;
	font-weight: bold;
	background-color: #3D71B1;
}

.plotColorsEditor .loggerEntry .plotColorEntries {
	font-size: 0.875rem;
}

.plotColorEntries .plotColorTableEntry td {
	vertical-align: middle;
}

.plotColorColumn .remove-color-button {
	display: flex;
	align-items: center;
	padding: 8px;
	margin: auto;
}

.loggerEntry .accordion-collapse.collapse {
	margin-left: 5px;
}