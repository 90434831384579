.new-project .name-entry>* {
	display: inline-block;
}

.new-project .name-entry .textField {
	min-width: 250px;
}

.new-project .name-entry>.btn {
	margin-left: 10px;
}

#settingsRightSpinner {
	position: absolute;
	left: calc(50% - 2.5rem);
	width: 5rem;
	height: 5rem;
}