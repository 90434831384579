.systems-config-save-button {
	margin-top: 10px;
	min-height: 45px;
}

.systems-config-save-button .config-button {
	width: 140px;
	height: 45px;
	text-transform: uppercase;
	letter-spacing: 2.5px;
	font-weight: 500;
	border: none;
	border-radius: 45px;
	box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
	transition: all 0.3s ease 0s;
	outline: none;
	margin-left: -70px;
}

@media only screen and (max-width: 767px) {
	.systems-config-save-button .config-button {
		margin-left: 0px;
	}
}

.systems-config-save-button .config-button.popup {
	position: fixed;
	bottom: 10px;
}

.systems-config-save-button .config-button:hover,
.systems-config-save-button .config-button.popup.sticky:hover {
	box-shadow: 0px 15px 20px rgba(46, 229, 157, 0.4);
	transform: translateY(-7px);
}

.systems-config-save-button .config-button.popup.sticky:disabled {
	opacity: var(--bs-btn-disabled-opacity);
}

.systems-config-save-button .config-button.popup.sticky {
	opacity: 1;
	transform: translateY(0)
}

.systems-config-save-button .config-button.popup.hide {
	opacity: 0;
	transform: translateY(100px);
	transition: all .5s ease
}