.alert-settings .email-addresses,
.alert-settings .email-header {
	margin-left: 10px;
	width: calc(100% - 10px);
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
}

.alert-settings .email-header,
.alert-settings .email-addresses {
	padding-bottom: 5px;
}

.alert-settings .email-addresses .card {
	margin-bottom: 5px;
}

.alert-settings .email-addresses .card-body {
	padding-left: 0px;
	padding-right: 0px;
}

.alert-settings .email-addresses .email-list-table-wrapper {
	max-height: 140px;
	overflow-y: auto;
}

.alert-settings .email-addresses .email-list-table-wrapper .email-list {
	margin-bottom: 0px;
}

.alert-settings .email-addresses .email-list-table-wrapper .email-list .email-item td.email-remove-button-cell {
	text-align: center;
	vertical-align: middle;
}

.alert-settings .email-addresses .add-button-wrapper {
	margin-top: 10px;
	text-align: center;
}