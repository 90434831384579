.report-copier .btn-light,
.report-copier .btn-light:hover {
	border: none;
}

.report-copier .alert {
	padding: 0.5rem 0.5rem;
	max-width: fit-content;
}

.report-copier #reportProjectDropdown,
#reportSourceDropdown {
	width: fit-content;
	position: relative;
}

#reportcopyRightSpinner {
	position: absolute;
	left: 10rem;
	top: 5rem;
	width: 5rem;
	height: 5rem;
}

.report-copier.cardBackground.loading {
	opacity: 50%;
	pointer-events: none;
}