/* Token view*/
.modal-dialog .modal-footer .open-tokenView.btn,
.modal-dialog .modal-footer .tokenButton {
	float: left;
}

.modal-dialog .tokenView {
	text-align: center;
}

.modal-dialog .tokenView > .btn:nth-of-type(1){
	margin-right: 200px;
}

.modal-dialog .tokenView > .title {
	font-size: 1.25rem;
	font-weight: bold;
	margin-bottom: 20px;
}
