.loggers-list,
.loggers-list .form-control {
	font-size: 0.875rem;
}

.loggers-list .logger-title {
	display: flex;
	white-space: nowrap;
}

.loggers-list .logger-title>.remove-logger-btn-wrapper {
	flex: 1 0 auto;
	text-align: right;
}

.loggers-list .name>* {
	display: inline-block;
}

.loggers-list .logger-title .title-main {
	flex: 1 0 auto;
	margin-right: 100px;
}

.loggers-list .logger-title .title-main>* {
	font-weight: bold;
}

.loggers-list .logger-title .title-main>*:not(:first-child) {
	margin-left: 100px;
}

.loggers-list .logger-title .title-main>.name .logger-name-input {
	max-width: 200px;
}

.loggers-list .name-input {
	width: 100px;
}

.loggers-list .logger-wrapper {
	margin-bottom: 20px;
}

.loggers-list .logger-wrapper tbody td>* {
	padding-left: 3px;
	padding-right: 3px;
}

.loggers-list .logger-wrapper.removed-logger {
	background-color: lightgray;
}

.loggers-list .logger-wrapper.added-logger {
	background-color: #5cb85c;
}

.loggers-list .logger-wrapper .logger-block {
	white-space: nowrap;
}

.loggers-list .logger-wrapper.mobile-logger-wrapper {
	overflow: auto;
}

.loggers-list .sensors-list-table thead tr th,
.loggers-list .sensors-list-table tbody tr td {
	text-align: center;
}

.loggers-list .sensors-list-table thead tr th:first-child,
.loggers-list .sensors-list-table tbody tr td:first-child {
	text-align: left;
}

.loggers-list .sensors-list-table tbody tr td.number-input .numberField.limit-input {
	width: 100px;
	text-align: center;
	margin-left: calc((100% - 100px) / 2);
}

.loggers-list>button {
	margin-bottom: 1rem;
}