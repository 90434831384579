div[role=alert] {
    margin-top: 10px;
}

.show-comments {
    margin-top: 0.25rem;
    margin-bottom: -10px;
}

.rightSidePanel .alert {
    text-align: left;
    padding: 1rem 5rem;
}

.comments-form label {
    margin-bottom: 0;
    margin-top: 0.5rem;
}

.comment-card {
    margin-top: 1rem;
}

.comment-card .card-header {
    text-align: left;
}

.comment-card .card-body {
    padding: 0.5rem 1rem;
}

.comment-card .card-body .blockquote p {
    font-size: 1.1rem;
}

.comment-card .card-body .blockquote-footer {
    font-size: 1rem;
}

.comment-card button {
    margin-left: 5rem;
}

.button-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.button-container button {
    display: flex;
    align-items: center;
}

.comment-dl-icon {
    stroke-width: 0.05rem;
    margin-right: 5px;
}

.comment-card .card-header p {
    margin-bottom: 0;
    float: left;
}

@media only screen and (max-width: 767px) {
    .show-comments {
        margin-bottom: 0px;
    }

    .rightSidePanel .alert {
        padding: 1rem;
    }

    .comment-card .card-header p {
        width: 70%;
    }

    .comment-card .card-header button {
        position: absolute;
        top: 5px;
        right: 5px;
    }

    .comment-card .card-body .blockquote-footer {
        font-size: 0.875rem;
    }
}

.wrap-newline {
    white-space: pre-line;
}