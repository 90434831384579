#tbg-radio-small+label {
    font-size: small;
}

#tbg-radio-medium+label {
    font-size: medium;
}

#tbg-radio-large+label {
    font-size: large;
}

#tbg-radio-small+label,
#tbg-radio-medium+label,
#tbg-radio-large+label {
    display: flex;
    align-items: flex-end;
    padding: 0 0.2rem;
}

.fontSizeWrapper .subtitle {
    margin-top: 10px;
}

.fontSizeFlexer {
    display: flex;
    flex-direction: row;
}

.fontSizeFlexer> :not(.dropdown) {
    flex: 0 0 auto;
}

.fontSizeWrapper .fontSizeFlexer .btn-check:checked+.btn,
.fontSizeWrapper .fontSizeFlexer .btn:hover {
    background-color: #3D71B1;
    border-color: #3D71B1;
}