/* Regen view*/
.modal-dialog .modal-footer .open-regenView.btn,
.modal-dialog .modal-footer .regenButton {
	float: left;
}

.modal-dialog .regenView {
	text-align: center;
}

.modal-dialog .regenView > .btn:nth-of-type(1){
	margin-right: 200px;
}

.modal-dialog .regenView > .title {
	font-size: 1.25rem;
	font-weight: bold;
	margin-bottom: 20px;
}
