.graphSettings {
	margin-bottom: 10px;
}

.graphSettings .graphPanel>.panel-collapse {
	width: 100%;
	overflow-y: auto;

}

.graphSettings .graphPanel>.panel-collapse>.card-body {
	position: static;
}

.graphSettings .graphPanel>.panel-collapse>.card-body>*:first-child {
	margin-top: 10px;
}

.graphSettings .titlePanel .card-body>*:not(:first-child),
.reportSelections {
	margin-top: 10px;
}

.graphSettings .accordion-button {
	text-align: center;
}

.graphSettings .accordion-body {
	padding: 0;
}

.graphSettings .accordion-button.card-header>div {
	width: 100%;
}

#settingsSpinner {
	position: absolute;
	left: calc(50% - 2rem);
	top: calc(70% - 2rem);
	width: 4rem;
	height: 4rem;
}

.qualityWrapper .subtitle {
	margin-top: 10px;
}

.accordion-button.collapsed::after,
.accordion-button:not(.collapsed)::after {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

#accordianPanel.menuPanel .accordion-collapse.collapse.show {
	padding: 0;
}