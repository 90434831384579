.users .leftSidePanel .card-body {
	text-align: center;
}

.users .leftSidePanel .card-body .btn.addUsers-button {
	margin-top: 10px;
}

.users .rightSidePanel {
	padding-top: 1rem;
}

.users .supportCustomersSelector {
	max-width: 100%;
}

.users .supportCustomersSelector {
	padding: 0px;
	margin: 0px;
}

.users .users-table-wrapper {
	max-height: 100%;
	overflow-y: auto;
}

.modal-dialog .supportCustomersSelector .table-wrapper {
	max-height: 40vh;
	overflow-y: auto;
}

.modal-dialog .supportCustomersSelector .table-wrapper .table {
	padding-bottom: 0px;
	margin-bottom: 0px;
}

.modal-dialog .user-table-column {
	padding-left: 0px;
	padding-right: 0px;
}

.modal-dialog .user-table-row>*:first-child {
	padding-right: 10px;
}

.modal-dialog .user-table-row>*:not(:first-child) {
	padding-left: 10px;
}

.modal-dialog .systemDropdown {
	margin-bottom: 10px;
}

.customerEditModal .form-label {
	font-weight: bold;
}

.user-table-row tbody tr {
	cursor: pointer;
}

#usersRightSpinner {
	position: absolute;
	left: calc(50% - 2.5rem);
	width: 5rem;
	height: 5rem;
}

.new-user-lang .langDropdown {
	max-width: max-content;
}

.projectsSelector {
	padding-top: 1rem;
}

@media (min-width: 576px) {

	.user-edit-modal .modal-header,
	.user-edit-modal .modal-body {
		padding-left: 3rem;
		padding-right: 3rem;
	}
}