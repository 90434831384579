.timezone-selector {
	width: 100%;
}

.timezone-selector .dropdown {
	width: 250px;
}

@media only screen and (max-width: 1024px) and (orientation: portrait) {
	.timezone-selector .dropdown {
		width: auto;
	}
}

.timezone-selector.mobile-timezone-selector .dropdown {
	width: auto;
}