#table {
    border-spacing: 1;
    border-collapse: collapse;
    background: white;
    border-radius: 0.625rem;
    overflow: hidden;
    width: 100%;
    margin: 0 auto;
    position: relative;
}

#table * {
    position: relative;
}

#table td,
#table th {
    padding-left: 0.5rem;
}

#table thead tr {
    height: 3.5rem;
}

#table thead th {
    background: #1e435c;
}

#table tbody tr {
    height: 3rem;
}

#table td,
#table th {
    text-align: left;
    vertical-align: middle;
}

#table td.l,
#table th.l {
    text-align: right;
}

#table td.c,
#table th.c {
    text-align: center;
}

#table td.r,
#table th.r {
    text-align: center;
}


#table th {
    font-size: 1.125rem;
    color: #fff;
    line-height: 1.2;
    font-weight: unset;
}

#table tbody tr:nth-child(even) {
    background-color: #f5f5f5;
}

#table tbody tr {
    font-size: 1rem;
    line-height: 1.2;
    font-weight: unset;
}

#table tbody tr:hover {
    color: #555555;
    background-color: #f5f5f5;
    cursor: pointer;
}

@media (min-width: 576px) {

    #table>thead>tr>th:first-child,
    #table>tbody>tr>td:first-child {
        padding-left: 2rem;
    }

    #table>thead>tr>th:last-child,
    #table>tbody>tr>td:last-child {
        padding-right: 2rem;
    }
}

@media (max-width: 575.98px) {
    #table.table> :not(caption)>*>* {
        padding: 0.3rem 0.3rem;
    }

    #table th {
        font-size: 1rem;
    }

    #table thead tr {
        height: 2.5rem;
    }

    .projectsSelector #table tbody tr {
        height: 2rem;
    }

    #table tbody tr {
        font-size: 0.875rem;
    }
}