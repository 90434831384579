.systems-config-logger-settings {
	margin-top: 10px;
	padding-right: 30px;
	padding-left: 30px;
}

@media only screen and (max-width: 1024px) and (orientation: portrait) {
	.systems-config-logger-settings {
		padding-right: 10px;
		padding-left: 10px;
	}
}

.systems-config-logger-settings>.title {
	font-size: 1.25rem;
	text-align: left;
	margin-bottom: 20px;
}