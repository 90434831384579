/* Dropdown flexing */
.dropdownFlexer {
	display: flex;
	flex-direction: row;
}

.dropdownFlexer .dropdown {
	flex: 1 1 auto;

	display: flex;
	flex-direction: row;
}

.dropdownFlexer .dropdown>button:first-of-type {
	text-align: left;
}

.dropdownFlexer .dropdown>button {
	display: flex;
	flex-direction: row;
	color: #333;
	background-color: #fff;
	border-color: #ccc;
	font-size: 1rem;
}

.dropdownFlexer .dropdown>button .caret {
	flex: none;
	margin-top: 8px;
}

.dropdownFlexer .dropdown>*:first-child {
	flex: 1 1 auto;
}

.dropdownFlexer .dropdown>*:not(button) {
	width: 100%;
}

.dropdownFlexer> :not(.dropdown) {
	flex: 0 0 auto;
}

.dropdownFlexer .dropdown .title {
	flex: 1 1 auto;
}

.dropdown-item {
	font-size: 1rem;
}