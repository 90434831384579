.command-history-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.command-history-table th, .command-history-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.command-history-table th {
    background-color: #f2f2f2;
    font-weight: bold;
}

