/* Misc */
.mainSelector .subtitle {
	margin-top: 10px;
	font-size: 1rem;
}

.mainSelector .exportButton {
	margin-left: 10px;
}

.mainSelector .projectSelectionWrapper,
.mainSelector .qualityWrapper {
	display: block;
}

.mainSelector .projectSelectionWrapper .dropdown.btn-group:not(.open),
.mainSelector .projectSelectionWrapper .dropdown.btn-group:not(.open) .projectDropdown,
.mainSelector .projectSelectionWrapper .dropdown.btn-group:not(.open) .projectDropdown .title {
	overflow: hidden;
}

.mainSelector .qualityWrapper .export-count-buttons {
	display: grid;
	grid-template-columns: calc(50% - 3px) calc(50% - 3px);
	width: 100%;
	margin-top: 10px;
	column-gap: 6px;
}

.mainSelector .qualityWrapper .export-count-buttons>.btn {
	margin: 0px;
}