.systems-config-system-settings {
	padding-right: 15%;
	padding-left: 30px;
}

.systems-config-system-settings>.title {
	font-size: 1.25rem;
	text-align: left;
	margin-bottom: 20px;
}

.systems-config-system-settings .subtitle {
	text-align: left;
	font-weight: bold;
}

.systems-config-system-settings>.general-settings,
.systems-config-system-settings>.logging-settings,
.systems-config-system-settings>.alert-settings {
	margin-bottom: 30px;
}

.systems-config-system-settings .settings-block {
	margin-left: 10px;
}

.systems-config-system-settings .setting {
	padding-top: 3px;
	padding-bottom: 3px;
}

.systems-config-system-settings .setting.separated-alignment-parent .separated-alignment-left.title {
	padding-right: 10px;
}

.separated-alignment-parent {
	width: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
}

@media only screen and (max-width: 1024px) and (orientation: portrait) {
	.systems-config-system-settings {
		padding-right: 10px;
		padding-left: 10px;
	}

	.separated-alignment-parent {
		border-bottom: 1px solid grey;
		margin-top: 4px;
	}

	.separated-alignment-parent:not(.switch-setting) {
		display: block;
	}

	.separated-alignment-parent:not(.switch-setting) .separated-alignment-left,
	.separated-alignment-parent:not(.switch-setting) .separated-alignment-right {
		display: block;
	}

	.separated-alignment-parent:not(.switch-setting) .separated-alignment-right {
		text-align: right;
	}

	.separated-alignment-parent.switch-setting {
		display: grid;
		grid-template-columns: auto 40px;
	}
}

.systems-config-system-settings.mobile-config-system-settings {
	padding-right: 10px;
	padding-left: 10px;
}

.systems-config-system-settings.mobile-config-system-settings .separated-alignment-parent {
	border-bottom: 1px solid grey;
	margin-top: 4px;
}

.systems-config-system-settings.mobile-config-system-settings .separated-alignment-parent:not(.switch-setting) {
	display: block;
}

.systems-config-system-settings.mobile-config-system-settings .separated-alignment-parent:not(.switch-setting) .separated-alignment-left,
.systems-config-system-settings.mobile-config-system-settings .separated-alignment-parent:not(.switch-setting) .separated-alignment-right {
	display: block;
}

.systems-config-system-settings.mobile-config-system-settings .separated-alignment-parent:not(.switch-setting) .separated-alignment-right {
	text-align: right;
}

.systems-config-system-settings.mobile-config-system-settings .separated-alignment-parent.switch-setting {
	display: grid;
	grid-template-columns: auto 40px;
}