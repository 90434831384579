.systems .leftSidePanel .card-body {
	text-align: left;
}

.systems .leftSidePanel .card-body .btn.addSystems-button {
	margin-top: 10px;
}

.systems .systems-table-wrapper {
	max-height: 100%;
	position: relative;
}

.systems .systems-table-wrapper table.systems-table {
	cursor: pointer;
}

.systems .rightSidePanel .system-current-status #table thead tr {
	height: 2.5rem;
}

.systems .rightSidePanel .system-current-status {
	position: relative;
	margin-top: 10px;
	margin-bottom: 10px;
	text-align: left;
}

#systemsRightSpinner {
	position: absolute;
	left: calc(50% - 2.5rem);
	top: calc(50% - 2.5rem);
	width: 5rem;
	height: 5rem;
}

.systems .leftSidePanel .nav {
	padding-top: 10%;
	padding-bottom: 10%;
	margin-right: 0;
}

.systems .leftSidePanel .nav-item {
	position: relative;
	z-index: 1;
	margin-bottom: 1px;
}

.systems .leftSidePanel .nav-link {
	border-radius: 0.5rem 0 0 0.5rem;
	border-right: 0;
	border-color: #ccc;
	color: #000;
}

.systems .leftSidePanel .nav-link.active {
	border-color: white;
}

.systems .leftSidePanel .nav-tabs .nav-link:hover,
.systems .leftSidePanel .nav-tabs .nav-link:focus {
	border-color: #3d71b1;
}

.systems .datasetSelect .accordion-button.card-header {
	border-radius: 0.375rem 0.375rem 0 0;
	cursor: default;
}

.systems .datasetSelect .accordion-button.card-header:hover {
	opacity: 1;
}

.systems .datasetSelect .accordion-button.card-header::after {
	display: none;
}

.systems .datasetSelect .accordion-button.card-header>div {
	width: 100%;
}

.systems .cardBackground:last-child .datasetSelect {
	margin-top: 5%;
}

.systems .systems-table-wrapper.cardBackground {
	border-radius: 0.625rem;
}