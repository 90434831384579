.alert-settings .sms-numbers {
	margin-left: 10px;
	width: calc(100% - 10px);
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
}

.alert-settings .sms-numbers .card {
	margin-bottom: 5px;
}

.alert-settings .sms-numbers .card-body {
	padding-left: 0px;
	padding-right: 0px;
}

.alert-settings .sms-numbers .numbers-list-table-wrapper {
	max-height: 140px;
	overflow-y: auto;
}

.alert-settings .sms-numbers .numbers-list-table-wrapper .numbers-list {
	margin-bottom: 0px;
}

.alert-settings .sms-numbers .numbers-list-table-wrapper .numbers-list .sms-item td.sms-remove-button-cell {
	text-align: center;
	vertical-align: middle;
}

.alert-settings .sms-numbers .add-button-wrapper {
	margin-top: 10px;
	text-align: center;
	margin-bottom: 10px;
}

.sms-item td {
	padding-bottom: 0;
}