.pageDefault {
	padding: 10px;
	min-height: calc(100vh - 50px);
	overflow-y: hidden;
	width: 100%;
	overflow: hidden;
	background-color: #E2DDD5;
}

@media only screen and (max-width: 767px) {
	.pageDefault {
		overflow: hidden;
		padding: 5px;
	}

	.fade.modal.show {
		z-index: 10050;
	}
}

/* Menu Card */
.pageDefault .menuPanel {
	padding: 0px;
	margin: 0px;
	text-align: center;
	border: none;
}

.pageDefault .menuPanel .card-header {
	cursor: pointer;
	padding: 3px;
}

.pageDefault .menuPanel .card-header:hover {
	opacity: 0.7;
}

.pageDefault .menuPanel .card-header {
	font-weight: bold;
}

.pageDefault .menuPanel .card-body {
	padding: 2px;
}

/* Static Menu Card */
.pageDefault .menuPanelStatic {
	padding: 0px;
	margin: 0px;
	text-align: center;
	border: none;
}

.pageDefault .menuPanelStatic .card-header {
	padding: 3px;
}

.pageDefault .menuPanelStatic .card-header {
	font-weight: bold;
}

.pageDefault .menuPanelStatic .card-body {
	padding: 2px;
	text-align: left;
}

.pageDefault .menuPanelStaticLoose .card-body {
	padding: 10px;
	text-align: left;
}

.menuPanelStaticLoose .card-body>* {
	margin-top: 10px;
	margin-bottom: 10px;
}

.card-body {
	padding-top: 5px;
}

.card-header {
	padding: 3px 10px;
}

.card-header {
	font-weight: bold;
	text-align: center;
}

.username .smallTextField {
	padding: 0.375rem 0.75rem;
}

.smallTextField {
	padding: 1px 5px;
}

.smallTextField input {
	padding: 1px 5px;
	font-size: 0.875rem;
}

#accordianPanel {
	overflow: inherit;
}

.dropdown-toggle::after {
	position: absolute;
	right: 12px;
	top: calc(50% - 3px);
	font-size: 1rem;
}

.btn-group-xs>.btn,
.btn-xs {
	font-size: 0.75rem !important;
	line-height: 1.5 !important;
	border-radius: 3px !important;
}

.btn.btn-success,
.btn.btn-danger {
	color: #FFFFFF !important;
}

.username.textField .title {
	font-size: 1rem;
}

.textField .title {
	font-size: 0.875rem;
}

.reactIcon-text {
	margin-right: 12px;
	font-size: 1.1rem;
	vertical-align: sub;
}

.reactIcon-refresh {
	font-size: 1.1rem;
	vertical-align: sub;
}

.reactIcon-edit {
	vertical-align: bottom;
	width: 1rem;
	height: auto;
}

.btn-light,
.btn-light:hover:not(:disabled) {
	border: 1px solid;
	border-color: black !important;
}

#loggername-tooltip .tooltip-inner {
	max-width: 100%;
}

.no-wrap {
	white-space: nowrap;
}

#accordianPanel>div.card {
	border: none;
}

.accordion div.card-header {
	border-width: 1px;
	border-color: rgba(0, 0, 0, 0.175);
	background-color: #1e435c;
	color: white;
}

.accordion .accordion-collapse.collapse.show {
	padding: 0px 10px 10px 10px;
}

.poweredby {
	height: 15px;
	font-size: 0.625rem;
	text-align: right;
}

.poweredby span.name {
	color: #007bff;
	margin-right: 20px;
}

.poweredby.pushDown {
	position: absolute;
	bottom: 5px;
	right: 5px;
}

.graph .no-data {
	position: relative;
	top: calc(50% - 5px);
	left: 50%;
}

.graph .no-data>* {
	position: relative;
	left: -50%;
}

.graph {
	height: 100%;
	padding: 15px 5px 5px 5px;
	background-color: #FFFFFF;
	border-radius: 5px;
	box-shadow: 3px 5px 10px rgba(0, 0, 0, 0.1);
	min-height: 90vh;
	position: relative;
}

.dropdown-item.active {
	min-width: fit-content;
}