.deviceNameEditor .deviceNameTable {
	text-align: center;
}

.deviceNameEditor .deviceNameItem {
	cursor: pointer;
}

.deviceNameItem td {
	padding: 5px;
}

.reactIcon-alert {
	fill: red;
	z-index: 1;
	width: 1.5rem;
	height: auto;
}

.deviceNameEditor .alert-icon-background {
	position: absolute;
	background-color: white;
	width: 14px !important;
	height: 14px;
	right: 25px;
}