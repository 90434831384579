.yAxisSelections .card-body {
	margin-top: 10px;
	display: grid;
	grid-template-columns: 1fr;
}

.yAxisSelections .card-body .side-fields.left {
	grid-column: 1/2;
	grid-row: 1/2;
}

.yAxisSelections .card-body .side-fields.right {
	grid-column: 1/2;
	grid-row: 2/3;
}

.yAxisSelections .card-body .side-fields {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 10px;
}


.yAxisSelections .card-body .side-fields > span.title {
	grid-column: 1/3;
	grid-row: 1/2;
	font-weight: bold;
	font-size: 0.875rem;
}

.yAxisSelections .card-body .yAxisTitle {
	grid-column: 1/3;
	grid-row: 2/3;
}

.yAxisSelections .card-body .yAxisMin {
	grid-column: 1/2;
	grid-row: 3/4;
}

.yAxisSelections .card-body .yAxisMax {
	grid-column: 2/3;
	grid-row: 3/4;
}


/* Ticks */
.yAxisSelections .card-body .yAxisTicks {
	grid-column: 1/3;
	grid-row: 3/4;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 10px;
}

.yAxisSelections .card-body .yAxisTicks > .title {
	grid-column: 1/3;
	grid-row: 1/2;
	font-weight: bold;
	font-size: 0.875rem;
}

.yAxisSelections .card-body .yAxisTicks .yAxisTicksValue {
	grid-column: 1/2;
	grid-row: 2/3;
}

.yAxisSelections .card-body .yAxisTicks .yAxisTickZero {
	grid-column: 2/3;
	grid-row: 2/3;
}

/* Y-axis side-selection */
.yAxisSelections .card-body .yAxisSide {
	grid-column: 1/3;
	grid-row: 4/5;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr;
	grid-column-gap: 10px;
}

.yAxisSelections .card-body .yAxisSide > .title {
	grid-column: 1/3;
	grid-row: 1/2;
	font-weight: bold;
	font-size: 0.875rem;
}

.yAxisSelections .card-body .yAxisSide .sideSelectionColumns {
	grid-column: 1/3;
	grid-row: 2/3;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr;
	max-height: 50vh;
	overflow-y: auto;
}

.yAxisSelections .card-body .yAxisSide .sideSelectionColumns > .left {
	grid-column: 1/2;
	grid-row: 2/3;
	border-right: 1px solid lightgray;
	padding: 5px 5px 5px 0px;
}

.yAxisSelections .card-body .yAxisSide .sideSelectionColumns > .right {
	grid-column: 2/3;
	grid-row: 2/3;
	border-left: 1px solid lightgray;
	padding: 5px 0px 5px 5px;
}

.yAxisSelections .card-body .yAxisSide .sideSelectionColumns .sideItem {
	display: block;
	margin: 10px 0px 10px 0px;
	padding: 1px 5px;
}