.alarms .leftSidePanel .settingsDropdown {
	margin-bottom: 10px;
}

.alarms .leftSidePanel .nav {
	padding-top: 10%;
	padding-bottom: 10%;
	margin-right: 0;
}

.alarms .leftSidePanel .nav-item {
	position: relative;
	z-index: 1;
	margin-bottom: 1px;
}

.alarms .leftSidePanel .nav-link {
	border-radius: 0.5rem 0 0 0.5rem;
	border-right: 0;
	border-color: #ccc;
	color: #000;
}

.alarms .leftSidePanel .nav-link.active {
	border-color: white;
}

.alarms .leftSidePanel .nav-tabs .nav-link:hover,
.alarms .leftSidePanel .nav-tabs .nav-link:focus {
	border-color: #3d71b1;
}

.alarms .rightSidePanel .accordion {
	padding: 2rem;
}

.alarms .rightSidePanel .cardBackground {
	min-height: 90vh;
}

.alarms .rightSidePanel .accordion-button:not(.collapsed) {
	background-color: #3D71B1;
	color: white;
}

.alarms .accordion-button.collapsed::after {
	background-image: var(--bs-accordion-btn-icon);
}

.alarms .rightSidePanel .accordion .date-range {
	margin-left: auto;
	font-weight: bold;
	min-width: 20%;
}

.alarms .rightSidePanel .accordion .project-name {
	min-width: 40%;
	font-weight: bold;
}

.alarms .rightSidePanel .new-project-button {
	margin: 2rem 2rem 0 2rem;
}

.alarms .rightSidePanel .reactIcon {
	vertical-align: middle;
}

.alarms .rightSidePanel .new-project {
	padding: 0 2rem;
}

.alarms .rightSidePanel .textField .title {
	font-size: 1rem;
}

.alarms .rightSidePanel .smallTextField {
	padding: 0.375rem 0.75rem
}

.alarms .rightSidePanel .show .accordion-body {
	display: flex;
	flex-direction: column;
}

@media (max-width: 575.98px) {
	.alarms .rightSidePanel .show .accordion-body {
		padding: 1rem 0 0 0;
	}

	.alarms .rightSidePanel .accordion-body .btn {
		margin-left: 5px;
	}
}

.alarms .rightSidePanel .accordion-body .project-buttons {
	display: flex;
}

.alarms .rightSidePanel .accordion-body .alarm-list {
	margin-top: 2rem;
	min-width: 50%;
	margin-left: auto;
	margin-right: auto;
	text-align: left;
}

.alarms .rightSidePanel .accordion-body .alarm-list-headers td{
	font-weight: bold;
	font-size: large;
	width: auto;
	text-align: left;
	
}

.alarms .rightSidePanel .accordion-body .alarm-list td {
	padding-left: 1rem;
}

.alarms .rightSidePanel .accordion-body .alarm-list h3 {
	text-align: left;
	padding-left: 1rem;
}

.alarms .rightSidePanel .accordion-body .alarm-list button {
	margin-top: 1rem;
}

.alarms .rightSidePanel .accordion-body .alarm-list .hidden-button {
	opacity: 0;
}

.alarms .rightSidePanel .accordion-body .alarm-list .logger-list-header {
	display: flex;
	justify-content: space-between;
	padding: 0 calc(25% - 16px) 0 calc(25% + 16px);
}

.alarms .rightSidePanel .accordion-body .alarm-list .logger-list-header>*,
.alarms .rightSidePanel .accordion-body .alarm-list .card-body>div {
	min-width: 25%;
	text-align: center;
}

.alarms .rightSidePanel .accordion-body .alarm-list .card-body {
	padding-top: 16px;
	display: flex;
	justify-content: space-between;
	padding-left: 5%;
	padding-right: 20%;
}

@media (min-width: 576px) {
	.alarms .rightSidePanel .accordion-body .btn-primary {
		margin: 0 1rem;
	}

	.alarms .rightSidePanel .accordion-body .btn-danger {
		margin-left: auto;
	}
}

.alarms .rightSidePanel .accordion-body .btn-primary .reactIcon {
	stroke-width: 0.05rem;
	vertical-align: baseline;
}

.alarms .rightSidePanel .accordion-body .alarm-list .reactIcon {
	stroke-width: 0;
}