.recording-interval-selector>.title {
	text-align: left;
}

.recording-interval-selector>.time-selector {
	text-align: right;
}

.recording-interval-selector>.time-selector .recording-interval-datetime {
	width: 140px;
}

.recording-interval-selector>.time-selector .recording-interval-datetime input.form-control {
	text-align: right;
}

.recording-interval-selector.separated-alignment-parent {
	width: 100%;
	display: block;
}

.recording-interval-selector.separated-alignment-parent .separated-alignment-left,
.recording-interval-selector.separated-alignment-parent .separated-alignment-right {
	display: block;
}