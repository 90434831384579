@media only screen and (min-width: 1200px) {
	.leftSidePanel {
		max-width: 360px;
		min-width: 360px;
		float: left;
	}

	.rightSidePanel {
		margin-left: 370px;
	}
}

@media only screen and (min-width: 768px) and (max-height: 1024px) and (orientation: landscape) {
	.leftSidePanel {
		max-width: 300px;
		min-width: 300px;
		float: left;
	}

	.rightSidePanel {
		margin-left: 310px;
		padding-top: 0 !important;
	}

	.leftSidePanel .flex-column.nav.nav-tabs.nav-fill {
		padding-top: 25%;
		margin-right: -10px;
	}

	.systems .cardBackground .datasetSelect {
		margin-top: 25%;
	}
}

.rightSidePanel {
	position: relative;
}

.leftSidePanel {
	position: relative;
}

.leftSidePanel>.cardBackground:first-child {
	margin-bottom: 10px;
}

.leftSidePanel .form-group {
	margin: 0px;
}

.leftSidePanel .mainSelector {
	margin-bottom: 10px;
	flex: none;
}

.leftSidePanel .menuPanel .card-body {
	text-align: left;
}

.datasetByTypeSelect,
.deviceNameEditor {
	flex: 0 1 auto;
}

.projectTitles>*,
.xAxisSelections>*,
.yAxisSelections>* {
	margin-top: 5px;
	margin-bottom: 5px;
}

.leftSidePanel .panelButtons {
	text-align: center;
}

/* Dropdown flexing */
.dropdownFlexer {
	display: flex;
	flex-direction: row;
}

.dropdownFlexer .dropdown {
	flex: 1 1 auto;
	display: block;
	flex-direction: row;
	max-width: 100%;
}

#deviceDropdown,
#reportDropdown,
#percentDropdown,
#settingsDropdown,
#typeDropdown,
#csvFromatDropdown,
#system-filtercoef-dropdown,
#systemDropdown,
#system-function-dropdown,
#reportProjectDropdown,
#reportSourceDropdown {
	padding: 7px 40px 7px 12px;
	width: 100%;
	border: 1px solid #e8eaed;
	border-radius: 5px;
	background: #fff;
	box-shadow: 0 1px 3px -2px #9098a9;
	cursor: pointer;
	transition: all 150ms ease;
}

.dropdownFlexer .dropdown>button:first-of-type {
	text-align: left;
}

.dropdownFlexer .dropdown>button {
	display: flex;
	flex-direction: row;
}

.dropdownFlexer .dropdown>button .caret {
	flex: none;
	margin-top: 8px;
}

.dropdownFlexer .dropdown>*:first-child {
	flex: 1 1 auto;
}

.dropdownFlexer .dropdown>*:not(button) {
	width: 100%;
}

.dropdownFlexer> :not(.dropdown) {
	flex: 0 0 auto;
}

.dropdownFlexer .dropdown .title {
	flex: 1 1 auto;
	overflow: hidden;
}