.blueprint {
    margin-top: 10px;
}

.pageDefault.blueprints {
    padding-bottom: 10px;
}

#file-upload {
    display: none;
}

.upload-blueprint-fields {
    margin-top: 10px;
}

.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
}

.uploadFileButton {
    margin-top: 10px;
    margin-left: 10px;
}

.uploadFilename {
    margin-left: 10px;
}

.blueprintDescription {
    resize: none;
    height: 8em !important;
}

.canvasContainer {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 84vh;
    margin-top: 6px;
}

.openseadragon-canvas span {
    z-index: 2;
}

.loggerImage {
    height: 48px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    filter: drop-shadow(4px 4px 4px #333);
}

.loggerFigure {
    display: inline-block;
    vertical-align: top;
    margin-left: 5px;
    margin-right: 5px;
}

.loggerFigure .tooltiptext {
    visibility: hidden;
    width: 30px;
    height: 30px;
    background-color: #dc3545;
    color: #fff;
    text-align: center;
    padding: 2px 10px;
    border-radius: 20px;
    /* Position the tooltip text */
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 100%;
    margin-left: -60px;
    /* Fade in tooltip */
    opacity: 0;
    transition: opacity 0.3s;
    font-family: "Glyphicons Halflings";
}

span.remove .loggerFigure .tooltiptext {
    visibility: visible;
    opacity: 1;
}

.loggerFigureAbsolute {
    display: inline;
    position: absolute;
}

.loggerFigureCaption {
    background-color: rgb(255 255 255 / 80%);
    font-weight: bold;
    border-radius: 5px;
    margin-top: 5px;
    padding-left: 5px;
    padding-right: 5px;
    text-align: center;
    font-size: 0.875rem;
}

.loggerFigureValuesContainer {
    text-align: left;
    font-size: 0.75rem;
}

.loggerFigureValue {
    color: #18151E;
    border-radius: 5px;
    font-weight: bold;
}

.loggerFigureTimestamp {
    font-size: 0.625rem;
    font-weight: normal;
    text-align: left;
}

.projectLoggerListPanel {
    margin-top: 10px;
    overflow-y: auto;
    max-height: 65vh;
}

.projectLoggerList {
    min-height: 400px;
    padding: 5px;
}

.rightSidePanel .deleteBlueprintButton {
    float: right;
    display: flex;
    align-items: center;
    padding: 8px;
    margin: auto;
}

.openseadragon-container :focus {
    outline: none;
}

.osd-custom-buttons {
    position: absolute;
    z-index: 100;
    top: 5px;
    right: 0px;
    color: rgb(105, 115, 138);
}

.osd-custom-buttons button {
    background-color: transparent;
    border: 0;
}

.osd-custom-buttons svg {
    fill: #c7c7c7
}

.osd-custom-buttons svg:hover {
    fill: #7b7b7b;
}

.osd-custom-button {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
}

.osd-custom-button .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: #687189;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 3px;

    /* Position the tooltip text */
    position: absolute;
    z-index: 1;
    top: 100%;
    left: -80%;
    margin-left: -60px;

    /* Fade in tooltip */
    opacity: 0;
    transition: opacity 0.3s;
}

/* Tooltip arrow */
.osd-custom-button .tooltiptext::after {
    content: " ";
    position: absolute;
    bottom: 100%;
    left: 83%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #687189 transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.osd-custom-button:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}

.osd-custom-button:focus {
    border: 0;
    outline: none;
}

.blueprintModal,
.blueprintModal .form-control,
.blueprintModal label {
    font-size: 0.875rem;
}

.blueprintLeftSidePanel .btn-group button,
.blueprintLeftSidePanel button {
    font-size: 0.875rem;
}

input[type="range"] {
    width: 100%;
}

.loggerDot {
    background-color: #fa0000;
    border-radius: 50%;
    display: inline-block;
    height: 15px;
    width: 15px;
    left: -7px;
    top: -7px;
    position: absolute;
}

.loggerDot.line:after {
    content: "";
    display: inline-block;
    height: 0.3em;
    vertical-align: middle;
    width: 110%;
    margin-right: -100%;
    margin-left: 5px;
    border-top: 3px solid #fa0000;
    transform: rotate(45deg);
}

.osd-custom-button:hover>* {
    fill: #7b7b7b;
}

#blueprint-dropdown::after {
    position: initial;
}

.edit-blueprint-modal .modal-body label, .upload-blueprint-modal .modal-body .upload-blueprint-fields label {
    display: block;
}

@media only screen and (max-width: 767px) {

    .blueprintContainer,
    .canvasContainer {
        max-height: 75vh;
    }
}