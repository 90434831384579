.setPassword {
	width: 100%;
	height: 100%;

	background: radial-gradient(#cc9d1c, #000000);
	display: flex;
	flex-direction: column;
}

.setPassword.neutral {
	background: radial-gradient(#cc9d1c, #000000);
}

.setPassword.invalid {
	background: radial-gradient(#941ccc, #000000);
}

.setPassword.fail {
	background: radial-gradient(#941818, #000000);
}

.setPassword.success {
	background: radial-gradient(#5ccc1c, #000000);
}

.setPassword .siteVersion {
	padding: 5px;
	position: absolute;
	right: 0px;
	bottom: 0px;
	color: rgb(187, 187, 187);
	font-size: 0.625rem;
}

.setPassword .formBox {
	flex: 0 1 none;

	margin-left: auto;
	margin-right: auto;
	margin-bottom: 20px;

	padding: 40px;
	border-radius: 5px;

	background-color: #FFFFFF;
	box-shadow: 5px 7px 10px rgba(0, 0, 0, 0.1);
}

.setPassword .formBox>*:not(:first-child) {
	margin-top: 20px;
}

.setPassword .formBox .textField,
.setPassword .formBox .passwordField {
	text-align: left;
}

.setPassword .langDropdown {
	flex: 1 1 none;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: auto;
	padding: 10px;
	border-radius: 5px;
	background-color: #FFFFFF;
	box-shadow: 5px 7px 10px rgba(0, 0, 0, 0.1);
}

.setPassword .msg {
	flex: 1 1 none;

	margin-top: auto;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 20px;

	padding: 10px;
	border-radius: 5px;

	background-color: #FFFFFF;
	box-shadow: 5px 7px 10px rgba(0, 0, 0, 0.1);
}

.setPassword .msg .spinner-border {
	margin-left: 12px;
}