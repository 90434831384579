.xAxisSelections .card-body {
	display: grid;
	grid-template-columns: 49% 49%;
	grid-template-rows: 1fr;
	grid-column-gap: 10px;
}

.xAxisSelections .card-body .xAxisTitle {
	grid-column: 1/3;
	grid-row: 1/2;
}

.xAxisSelections .card-body .timeStart {
	grid-column: 1/2;
	grid-row: 2/3;
}

.xAxisSelections .card-body .timeEnd {
	grid-column: 2/3;
	grid-row: 2/3;
}

.xAxisSelections .timeEnd .rdtPicker {
	left: -120px;
}

.xAxisSelections .card-body .xAxisTicks {
	grid-column: 1/3;
	grid-row: 4/4;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-template-rows: 1fr;
	grid-column-gap: 10px;
}

.xAxisSelections .card-body .xAxisTicks>.title,
.xAxisSelections .card-body .xAxisPeriod>.title {
	grid-column: 1/4;
	grid-row: 1/2;
	font-weight: bold;
}

.xAxisSelections .card-body .xAxisTicks .title,
.xAxisSelections .card-body .xAxisPeriod .title {
	font-size: 0.875rem;
}

.xAxisSelections .card-body .xAxisTicks .xAxisTicksDays {
	grid-column: 1/2;
	grid-row: 2/3;
}

.xAxisSelections .card-body .xAxisTicks .xAxisTicksHours {
	grid-column: 2/3;
	grid-row: 2/3;
}

.xAxisSelections .card-body .xAxisTicks .xAxisTicksMinutes {
	grid-column: 3/4;
	grid-row: 2/3;
}

.xAxisSelections .card-body .xAxisTicks .xAxisTickZero {
	grid-column: 1/3;
	grid-row: 3/4;
}

.xAxisSelections .card-body .xAxisTicks .xAxisTickZeroReset {
	grid-column: 3/4;
	grid-row: 3/4;
	text-align: center;
	position: relative;
}

.xAxisSelections .card-body .xAxisTicks .xAxisTickZeroReset .btn {
	position: absolute;
	left: 0;
	bottom: 0;
}

.react-datepicker .react-datepicker__day--keyboard-selected {
	background-color: transparent;
	color: #18151E;
	font-weight: normal;
}

.xAxisSelections .card-body .xAxisPeriod {
	grid-column: 1/3;
	grid-row: 3/4;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-template-rows: 1fr;
	grid-column-gap: 10px;
}

.xAxisSelections .card-body .xAxisPeriod .filterStart {
	grid-column: 1/2;
	grid-row: 2/3;
}

.xAxisSelections .card-body .xAxisPeriod .filterEnd {
	grid-column: 2/3;
	grid-row: 2/3;
}

.xAxisSelections .card-body .xAxisPeriod button {
	grid-column: 3/4;
	grid-row: 2/3;
	margin-top: 21px;
}

.xAxisSelections .card-body .xAxisPeriod form {
	grid-column: 1/3;
	font-weight: bold;
}